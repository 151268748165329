<template>
  <b-modal
    id="delete-scheduled-message"
    centered
    title="Remove Scheduled Messages"
    ok-title="Remove"
    ok-variant="danger"
    cancel-title="Cancel"
    cancel-variant="secondary"
    @ok="deleteScheduledMessage"
  >
    <b-card-text> Are you sure you want to remove this batch of scheduled messages from ever being sent ? </b-card-text>
  </b-modal>
</template>

<script>
import { BModal, BCardText } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BModal,
    BCardText,
  },
  props: ["id"],
  emits: ["scheduled-refresh"],
  methods:{
    deleteScheduledMessage(){
      this.$http
        .get("/scheduled-messages/remove/"+ this.id)
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: response.data.message,
              icon: "CheckIcon",
              variant: "success",
            },
          });
          this.$emit('scheduled-refresh')
        })
        .catch((error) => {
          for (let err of error.response.data.error) {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: err,
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          }
          this.$emit('scheduled-refresh')
        });
    }
  }
};
</script>
